<template lang="pug">
  div(v-if='isReady')
    .card.card-custom.card-stretch.gutter-b
      .card-header.border-0.pt-5
        h3.card-title.align-items-start.flex-column
          span.card-label.font-weight-bolder.text-dark
            | {{ $t('gis.batch_data_list') }}
        .card-toolbar
          ul.nav.nav-pills.nav-pills-sm.nav-dark-75
            li
              router-link.btn.btn-sm.btn-info(:to="{name: 'gis.add_batch'}")
                | {{ $t('GENERAL.add') }}
      .card-body.pt-3.pb-0
        .table-responsive
          table.table.table-borderless.table-vertical-center(v-if='batch_data')
            thead
              tr
                th.p-0(style='width: 15%')
                  | {{ $t('gis.batch_name') }}
                th.p-0(style='width: 50%')
                  | {{ $t('gis.batch_desc') }}
                th.p-0(style='min-width: 10%')
                  | {{ $t('GENERAL.last_update') }}
                th.p-0.text-right(style='min-width: 10%')
                  | {{ $t('GENERAL.option') }}
            tbody
              template(v-for='(item, i) in batch_data')
                tr(v-bind:key='i')
                  td.pl-0
                    a.text-dark-75
                      router-link(:to="{name: 'gis.edit_batch', params: {id: item.id}}")
                        | {{ item.name }}
                  td.pl-0
                    a.text-dark-75
                      | {{ item.description ? item.description : '-' | str_limit(128) }}
                  td.pl-0
                    div
                      a.text-muted.font-weight-bold
                        | {{ item.lu_datetime }}
                  td.text-right.pr-0
                    router-link.btn.btn-icon.btn-light.btn-sm.mx-3(:to="{name: 'gis.edit_batch', params: {id: item.id}}")
                      span.svg-icon.svg-icon-md.svg-icon-primary
                        // begin::Svg Icon
                        inline-svg(src='media/svg/icons/Communication/Write.svg')
                        // end::Svg Icon
                    a.btn.btn-icon.btn-light.btn-sm(@click='deleteConfirmationModal(item.id)' ref='btnShow')
                      span.svg-icon.svg-icon-md.svg-icon-danger
                        // begin::Svg Icon
                        inline-svg(src='media/svg/icons/General/Trash.svg')
                        // end::Svg Icon
          .text-center(v-else='') No Data
  .card.card-custom.card-stretch.gutter-b(v-else='')
    .card-body.pt-3.p-20
      .d-flex.justify-content-center.mb-3
        b-spinner(label='Loading...')

</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import * as Services from '../services.js';

export default {
  name: 'gis_batch_list',
  data() {
    return {
      isReady: false,
      batch_data: null,
      total_post: 0,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: 'GIS', route: '/admin/gis'}]);

    this.$store
      .dispatch(Services.GET_BATCH_LIST)
      .then(res => {
        this.isReady = true;
        this.batch_data = res.data;
        this.total_post = res.data.length;
      })
      .catch(error => {
        // console.log(error);
      });
  },
  methods: {
    deleteConfirmationModal(id) {
      this.deleteConfirm(() => {
        this.isReady = false;
        this.$store
          .dispatch(Services.DELETE_BATCH_DATA, {id: id})
          .then(res => {
            this.isReady = true;
            if (res.status) {
              this.$store
                .dispatch(Services.GET_BATCH_LIST)
                .then(res => {
                  this.batch_data = res.data;
                  this.total_post = res.data.length;
                })
                .catch(error => {});
            } else {
              this.$bvToast.toast(res.message, {
                title: 'Error!',
                variant: 'danger',
                solid: true,
              });
            }
          })
          .catch(error => {});
      });
    },
  },
};
</script>

<style></style>
